/**
 * FILE: auth.js
 * This holds all the vuex auth state variables at that are needed across the application.
 */

import { make } from "vuex-pathify"

const state = {
  user: null
}

const getters = {
  ...make.getters(state),

  isAuthenticated: () => {
    return state.user ? true : false
  },

  email:() => {
    return state.user ? state.user.claims.email : null
  },

  name: () => {
    return state.user ? state.user.claims.name : null
  },

  picture: () => {
    return state.user ? state.user.claims.picture : null
  },

  isAdmin: () => {
    return state.user ? state.user.claims.isAdmin : false
  },

  isMember: () => {
    return state.user ? state.user.claims.isMember : false
  },

  userId: () => {
    return state.user ? state.user.claims.user_id : null
  }
}

const actions = {
  ...make.actions(state)
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
