/**
 * @file prelaunch.js
 * @author Andy Nelson
 *
 * Prelaunch router configuration file
 */

/** Prelaunch route names */
export const prelaunchNames = Object.freeze({
  home: "prelaunch",
  page: "prelaunch:page"
})

/** Prelaunch routes */
export const prelaunchRoutes = [
  {
    path: "prelaunch",
    component: () => import("./../layouts/Prelaunch"),
    children: [
      {
        path: "",
        name: prelaunchNames.home,
        meta: { title: "Home" },
        component: () =>
          import(
            /* webpackChunkName: "prelaunch" */ "./../views/prelaunch/Home"
          )
      },
      {
        path: ":id",
        name: prelaunchNames.page,
        component: () =>
          import(
            /* webpackChunkName: "prelaunch" */ "./../views/prelaunch/Default"
          )
      }
    ]
  }
]