/**
 * FILE: app.js
 * This holds all the base app state variables at that are needed across the application.
 */

import { make } from "vuex-pathify"

const state = {
  pageTitle: ""
}

const getters = {
  ...make.getters(state),
}

const actions = {
  ...make.actions(state)
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
