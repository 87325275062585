/**
 * @file member.js
 * @author Andy Nelson
 *
 * Member router configuration file
 */

import SimpleRouterView from "./../components/SimpleRouterView"

export const memberNames = Object.freeze({
  home: 'my',
  tracking: 'my:tracking',
  profile: 'my:profile',
  infoDesk: 'my:infoDesk',
  infoDeskPage: 'my:infoDeskPage',
  newsletter: 'my:newsletter',
  survey: 'my:survey',
  fellowMembers: 'my:fellowMembers',
  project: 'my:project',
  projects: 'my:projects',
  artist: 'my:artist',
  artists: 'my:artists'
})

export const memberRoutes = [
  {
    path: 'my',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "my" */ './../layouts/Member'),
    children: [
      {
        path: '',
        name: memberNames.home,
        meta: { title: 'Base Camp' },
        component: () =>
          import(/* webpackChunkName: "my" */ './../views/member/Basecamp')
      },
      {
        path: 'tracking',
        name: memberNames.tracking,
        meta: { title: "I'm Tracking" },
        component: () =>
          import(/* webpackChunkName: "my" */ './../views/member/Tracking')
      },
      {
        path: 'profile',
        name: memberNames.profile,
        meta: { title: 'Profile' },
        component: () =>
          import(/* webpackChunkName: "my" */ './../views/member/Profile')
      },
      {
        path: 'infodesk',
        component: SimpleRouterView,
        children: [
          {
            path: '',
            name: memberNames.infoDesk,
            meta: { title: 'Information Desk' },
            component: () =>
              import(/* webpackChunkName: "my" */ './../views/member/InfoDesk')
          },
          {
            path: 'infodesk/:id',
            name: memberNames.infoDeskPage,
            meta: { title: 'Information Desk' },
            component: () =>
              import(/* webpackChunkName: "my" */ './../views/member/InfoDesk')
          }
        ]
      },
      {
        path: 'newsletter',
        name: memberNames.newsletter,
        meta: { title: 'Newsletter' },
        component: () =>
          import(/* webpackChunkName: "my" */ './../views/member/Newsletter')
      },
      {
        path: 'survey',
        name: memberNames.survey,
        meta: { title: 'Surveys' },
        component: () =>
          import(/* webpackChunkName: "my" */ './../views/member/Survey')
      },
      {
        path: 'projects',
        component: SimpleRouterView,
        children: [
          {
            path: '',
            name: memberNames.projects,
            meta: { title: 'Projects' },
            component: () =>
              import(/* webpackChunkName: "my" */ './../views/member/Projects')
          },
          {
            path: ':slug',
            name: memberNames.project,
            meta: { title: 'Project' },
            component: () =>
              import(/* webpackChunkName: "my" */ './../views/member/Project')
          }
        ]
      },
      {
        path: 'artists',
        component: SimpleRouterView,
        children: [
          {
            path: '',
            name: memberNames.artists,
            meta: { title: 'Artists' },
            component: () =>
              import(/* webpackChunkName: "my" */ './../views/member/Artists')
          },
          {
            path: ':slug',
            name: memberNames.artist,
            meta: { title: 'Artist' },
            component: () =>
              import(/* webpackChunkName: "my" */ './../views/member/Artist')
          }
        ]
      }
    ]
  }
]
