<template>
  <div id="app">
    <Toaster></Toaster>
    <Confirm ref="confirm"></Confirm>
    <router-view></router-view>
  </div>
</template>

<script>
import Toaster from "./components/Toaster"
import Confirm from "./components/ConfirmDialog"

export default {
  name: "App",
  components: {
    Toaster,
    Confirm
  },
  computed: {
    layout() {
      return Admin
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  }
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.header-safe-area-fix {
    // iOS < 11.2
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    height: calc(48px + constant(safe-area-inset-top)) !important;

    // iOS >= 11.2
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    height: calc(48px + env(safe-area-inset-top)) !important;
}

.ios-dialog {
    li div {
        line-height: 30px;
        display: flex;
        align-items: center;

        .ios-icon {
            width: 30px;
            height: 30px;
            margin-left: 4px;
            margin-right: 4px;
        }
    }
}

</style>
