/**
 * @file public.js
 * @author Andy Nelson
 *
 * Public router configuration file
 */

import DefaultComponent from "./../views/Default"

/** Public route names */
export const publicNames = Object.freeze({
  home: "home",
  register: "register",
  login: "login",
  companyPage: "companyPage",
  discoverProjects: "discoverProjects",
  discoverTeams: "discoverTeams"
})

const layout = "default"

/** Pulbic routes */
export const publicRoutes = [
  {
    path: 'login',
    component: () =>
      import(/* webpackChunkName: "layouts" */ './../layouts/Blank'),
    children: [
      {
        path: '',
        name: publicNames.login,
        component: () =>
          import(/* webpackChunkName: "login" */ './../views/Login')
      }
    ]
  },
  {
    path: 'home',
    component: () =>
      import(/* webpackChunkName: "layouts" */ './../layouts/Public'),
    children: [
      {
        path: '',
        name: publicNames.home,
        component: () => import('./../views/Home')
      }
    ]
  },
  {
    path: 'register',
    name: publicNames.register,
    meta: { layout: layout },
    component: () => import('./../views/Register')
  },
  // {
  //   path: 'login',
  //   name: publicNames.login,
  //   meta: { layout: layout },
  //   component: () => import('./../views/Login')
  // },
  {
    path: 'company/:slug',
    name: publicNames.companyPage,
    meta: { layout: layout },
    component: DefaultComponent
  },
  {
    path: 'discover',
    children: [
      {
        path: 'teams',
        name: publicNames.discoverTeams,
        meta: { layout: layout },
        component: DefaultComponent
      },
      {
        path: 'projects',
        name: publicNames.discoverProjects,
        meta: { layout: layout },
        component: DefaultComponent
      }
    ]
  }
]
