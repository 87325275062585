<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout" :top="true">
    {{ message }}
    <v-btn text class="ml-5" @click="show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "",
      timeout: 3000
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showToaster") {
        this.message = state.toasterMessage
        this.color = state.toasterColor
        this.timeout = state.toasterTimeout
        this.show = true
      }
    })
  }
}
</script>
