/**
 * @file firebase.js
 * @author Andy Nelson
 *
 * Google Firebase cpnnection management and utility file. The application configuration is
 * loaded based upon the environment. Utility methods are established to help during
 * development and to enforce DRY.
 */

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import store from './../store'
import { router, routeNames } from './../router'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DB,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
}

firebase.initializeApp(firebaseConfig)

/** Alias to firebase.firestore() */
export const db = firebase.firestore()

/** Alias to firebase.auth() */
export const auth = firebase.auth()

/** Alias to firebase.functions() */
export const functions = firebase.functions()

/**
 * Sets the user storage preference (not the loacal storage) to determine
 * how the session is maintained after a browser refresh or shutdown
 */
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

// FIRESTORE TYPES
const { Timestamp, GeoPoint, FieldValue } = firebase.firestore

/**
 * Fetches the user's claims from Google's firestore authentication system and
 * sets the store's user with the result.
 * @async
 * @method
 * @param {Object} user
 *
 * @returns {Object} User's claims object
 */
export async function fetchUserClaims() {
  firebase
    .auth()
    .currentUser.getIdTokenResult(true)
    .then((idTokenResult) => {
      store.set('auth/user', idTokenResult)
    })
    .catch((error) => {
      store.set('auth/user', null)
      console.error(error)
    })
}

/**
 * Signs the user session out from Goggle's authentication system.
 * @async
 *
 * @returns {void}
 */
export async function logout() {
  await auth.signOut()
  store.set('auth/user', null)

  if (router.currentRoute.path !== "/") {
    router.push("/")
  }
}

/** Firebase, and firebase type exports */
export { firebase, Timestamp, GeoPoint, FieldValue }
