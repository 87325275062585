import Vue from 'vue'
import { firestorePlugin } from "vuefire"
import App from './App.vue'
import PrismicVue from '@prismicio/vue'
import './registerServiceWorker'
import { router } from "./router"
import store from './store'
import vuetify from './plugins/vuetify';
import toaster from "./plugins/toaster"
import { auth, fetchUserClaims, logout } from "./services/firebase"

/** Turns off the 'you are running in development mode' message */
Vue.config.productionTip = false

Vue.use(toaster)
Vue.use(firestorePlugin)
Vue.use(PrismicVue, {
  endpoint: 'https://ridgewolf-one.cdn.prismic.io/api/v2'
})

/** Application root event bus */
export const eventBus = new Vue()

let app

auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      el: "#app",
      components: { App },
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app")
  }

  if (user) {
    fetchUserClaims()
  } else {
    store.set('auth/user', null)
  }
})
