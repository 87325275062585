/**
 * @file toaster.js
 * @author Andy Nelson
 *
 * Manages a generic, app-wide toaster notification. Inspiration with a
 * little help from our friends:
 * https://learnvue.co/2020/01/how-to-make-your-first-vuejs-plugin/
 */

/** Project imports */
import store from "./../store"

/**
 * Plugin to handle showing custom notifications on an app-wide basis
 */
export default {
  /**
   * Installs the plugin
   * @param {Object} Vue - Vue instance
   */
  install(Vue) {
    Vue.prototype.$toaster = function(payload) {
      store.commit("showToaster", {
        message: payload.message,
        color: payload.color,
        timeout: payload.timeout
      })
    }
  }
}
