/**
 * @file admin.js
 * @author Andy Nelson
 *
 * Admin router configuration file
 */

export const adminNames = Object.freeze({
  home: "admin",
  teams: "admin:teams",
  teamsAdd: "admin:teams:add",
  teamProjects: "admin:team:projects",
  prismicPreview: "admin:prismicPreview",
  test: "admin:test",
  survey: "admin:survey",
  colors: "admin:colors"
})

const layout = "admin"

export const adminRoutes = [
  {
    path: 'admin',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "admin" */ './../layouts/Admin'),
    children: [
      {
        path: '',
        name: adminNames.home,
        meta: { title: 'Admin Home' },
        component: () =>
          import(/* webpackChunkName: "admin" */ './../views/admin/Home')
      },
      {
        path: 'teams',
        name: adminNames.teams,
        meta: { title: 'Teams' },
        component: () =>
          import(/* webpackChunkName: "admin" */ './../views/admin/Teams')
      },
      {
        path: 'teams/add',
        name: adminNames.teamsAdd,
        meta: { title: 'Add a Team' },
        component: () =>
          import(/* webpackChunkName: "admin" */ './../views/admin/Team')
      },
      {
        path: 'teams/:id',
        name: adminNames.teamProjects,
        meta: { title: 'Team' },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "admin" */ './../views/admin/TeamProjects'
          )
      },
      {
        path: 'prismic',
        name: adminNames.prismicPreview,
        meta: { title: 'Preview' },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ './../views/admin/PrismicPreview'
          )
      },
      {
        path: 'test/:slug',
        name: adminNames.test,
        meta: { title: 'Test' },
        component: () =>
          import(/* webpackChunkName: "admin" */ './../views/admin/Test')
      },
      {
        path: 'survey/:slug',
        name: adminNames.survey,
        meta: { title: 'Survey' },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ './../views/admin/SurveyPreview'
          )
      },
      {
        path: 'colors',
        name: adminNames.colors,
        meta: { title: 'Application Colors' },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ './../views/admin/ColorsPreview'
          )
      }
    ]
  }
]