/**
 * @file index.js
 * @author Andy Nelson
 *
 * Main router configuration file
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

import SimpleRouterView from "./../components/SimpleRouterView.vue"
import { adminNames, adminRoutes } from "./admin"
import { prelaunchNames, prelaunchRoutes } from "./prelaunch"
import { publicNames, publicRoutes } from "./public"
import { memberNames, memberRoutes } from "./member"
import { auth } from "./../services/firebase"
import store from "./../store"

Vue.use(VueRouter)

/** Application route names */
export const routeNames = Object.freeze({
  home: "home",
  prelaunch: prelaunchNames,
  public: publicNames,
  member: memberNames,
  admin: adminNames
})

const routes = [
  {
    path: "/",
    redirect: { name: routeNames.prelaunch.home },
    component: SimpleRouterView,
    children: [
      ...prelaunchRoutes,
      ...publicRoutes,
      ...memberRoutes,
      ...adminRoutes
    ]
  },
  { path: "*", redirect: { name: routeNames.prelaunch.home } }
]

/** Application router */
export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior moves to the top of the page when swithing routes.
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  document.title = document.title = to.meta.title
    ? "Ridgewolf - " + to.meta.title
    : "Ridgewolf"

  store.set('app/pageTitle', to.meta.title ? to.meta.title : '')

  if (requiresAuth && !auth.currentUser) {
    next("/")
  } else if (requiresAuth && auth.currentUser) {
    next()
  } else {
    next()
  }
})
