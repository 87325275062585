import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#485961',
        accent: '#FF4081',
        secondary: '#ffe18d',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: {
        primary: '#485961',
        accent: '#F46C36',
        secondary: '#B2967D',
        success: '#6A994E',
        info: '#5B7F9C',
        warning: '#E09F3E',
        error: '#BC4749'
      }
    }
  }
})
